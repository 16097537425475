import React from "react";
import { Link } from "react-router-dom";
import CampaignCard from "./common/CampaignCard";
import Spinner from "./layout/Spinner";

function CampaignComponent({ products, loading, hide }) {
  return (
    <div>
      {!loading ? (
        <section className="causes ptb-80">
          <div className="auto-container">
            <div className="sec-title centered mb-5">
              <h2>
                Sharing Happiness
                {!hide && (
                  <span>
                    <Link to="/campaigns"> View All </Link>
                  </span>
                )}
              </h2>
              <div className="text">We believe what we achieve</div>
            </div>
            <div className="row clearfix">
              {products &&
                products.map((product) => {
                  return <CampaignCard campaign={product} />;
                })}
            </div>
          </div>
        </section>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default CampaignComponent;
