import React from "react";
import VolunteerCard from "./common/VolunteerCard";
import Spinner from "./layout/Spinner";
import { Link } from "react-router-dom";
function Volunteer({ volunteers, loading, hide }) {
  return (
    <div>
      <section className="volunteer">
        <div className="auto-container">
          <div className="sec-title centered mb-5">
            <h2>
              Our Volunteers{" "}
              {!hide && (
                <span>
                  <Link to="/volunteers"> View All </Link>
                </span>
              )}
            </h2>
            <div className="text">We believe what we achieve</div>
          </div>
        </div>
        {!loading ? (
          <div className="auto-container">
            <div className="row">
              {volunteers &&
                volunteers.map((volunteer) => {
                  return <VolunteerCard volunteer={volunteer} />;
                })}
            </div>
          </div>
        ) : (
          <div className="auto-container">
            <div className="row">
              <Spinner />
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default Volunteer;
