import React from "react";
import { connect } from "react-redux";
import { removeAlert } from "../../store/actions/alert";
const AlertBox = ({ alert, removeAlert }) => {
  return (
    <>
      {alert && (
        <div>
          {alert.map((alert, alert_inc) => {
            return (
              <div
                key={`alert-${alert_inc}`}
                className={`alert-box message-${alert.alertType}`}
                onClick={() => removeAlert(alert.id)}
              >
                <div className="d-flex justify-content-center">
                  <div className="alert-main-icon">
                    <i
                      className={
                        alert.alertType === "success"
                          ? "fa fa-check-circle-o"
                          : "fa fa-times-circle-o"
                      }
                    ></i>
                  </div>
                  <div className="alert-message">
                    <div className="alert-heading">
                      {alert.alertType === "success" ? "Success" : "Error"}
                    </div>
                    <div className="alert-content">{alert.msg}</div>
                  </div>
                  <div className="alert-close-icon">
                    <i className="fa fa-close"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = { removeAlert };

export default connect(mapStateToProps, mapDispatchToProps)(AlertBox);
