import React from 'react'

function AboutSection() {
    return (
        <div>
           <section className="about">
  <div className="auto-container">
    <div className="row">
      <div className="col-md-7">
        <div className="about-us-content ptb-80">
          <h2>Welcome To <br /><span className="theme_color">Save The Quest</span> Foundation</h2>
          <div className="bold-text">
            We help Poor children to get their life better.
          </div>
          <div className="paragraph-text mt-3">
            <p> <b className="red-co">SAVE THE QUEST (STQ)</b> is a charitable trust registered under the Indian Trust Act of 1882, Section 60 (Registration no. 822) based in Delhi. The society we live in is plagued by prejudices and clichéd notions. Differently abled, orphans or the financially weak are easy targets of these judgmental eyes. It is high time and these targets need to be appreciated for their ability, for their passion, for their conviction. They are different, but not an outsider.</p>
            <p>We at STQ try to make them a part of the society; we become one of them and work as a team. Our volunteers act as friends and mentors to imbibe the right values and educate them. Our mission is about bringing revolutionary, positive changes in the society by the means of education. We stand for student rights and uphold the philosophy of pragmatic, quality-based quest-based studies.</p>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="about-us-image pt-80 mt-5">
          <img src="assets/images/scribe-img.png" />
        </div>
        {/* <div class="about-us-2-image">
                  <img src="https://savethequest.s3.us-west-1.amazonaws.com/578402_522333634513001_1683408446_n_9bbc0d1d2c.jpg">
              </div> */}
      </div>
    </div>
  </div>
</section>

        </div>
    )
}

export default AboutSection
