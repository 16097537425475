import {
  GET_WISHS,
  ADD_WISH,
  EDIT_WISH,
  GET_WISH,
  RESET_WISH,
  WISHS_ERROR,
} from "../types/wish_type";

const initialState = {
  wishs: null,
  wish: null,
  total_wishs: 0,
  loading: true,
  error: {},
  wish_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_WISHS:
      return {
        ...state,
        wishs: payload,
        loading: false,
        error: {},
      };

    case RESET_WISH:
      return {
        ...state,
        wish: null,
        loading: true,
        error: {},
      };

    case ADD_WISH:
      return {
        ...state,
        wish_message: payload,
        loading: false,
        error: {},
      };
    case GET_WISH:
      return {
        ...state,
        wish: payload,
        loading: false,
        error: {},
      };
    case EDIT_WISH:
      return {
        ...state,
        wish_message: payload,
        loading: false,
        error: {},
      };

    case WISHS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
