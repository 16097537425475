import React from 'react'
import renderHTML from 'react-render-html'
import { Link } from 'react-router-dom'
function WishRequestCard({
  wish: { featured_image, title, content, goal, achieved, slug },
}) {
  return (
    <div className="col-md-4">
      <Link to={`/wishes/${slug}`}>
        <div className="cause-block">
          <div className="inner-box">
            <div className="image-box">
              <Link to={`/wishes/${slug}`}>
                <img src={featured_image} />
              </Link>
            </div>
          </div>
          <div className="lower-content">
            <h3>
              <Link to={`/wishes/${slug}`}>{title}</Link>
            </h3>
            <div className="cause-title">
              <p>Save The Quest</p>
            </div>
            <div className="text">{content && renderHTML(content)}</div>
            <div className="progress-box">
              <div className="bar">
                <div
                  className="bar-inner count-bar counted"
                  data-percent="80%"
                  style={{ width: `${parseInt((achieved / goal) * 100)}%` }}
                >
                  <div className="count-text">
                    {parseInt((achieved / goal) * 100)}%
                  </div>
                </div>
              </div>
            </div>
            <div className="donate-info clearfix mt-3">
              <div className="goal">
                <i className="fa fa-crosshairs" />
                <span>Goal</span>
                <strong>{goal}</strong>
              </div>
              <div className="raised">
                <i className="fa fa-bar-chart" aria-hidden="true" />
                <span>Raised:</span>
                <strong>{achieved}</strong>
              </div>
            </div>
            <div className="link-box text-center">
              <Link to={`/wishes/${slug}`} className="theme-btn btn-style-two ">
                <span className="btn-title">Donate Now</span>
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default WishRequestCard
