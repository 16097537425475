import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CustomDonateForm from "../../components/campaign/CustomDonateForm";
import DonationForm from "../../components/common/DonationForm";
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import PageBanner from "../../components/common/PageBanner";
import Scanner from "../../components/common/Scanner";
import OfflineDonation from "../../components/OfflineDonation";
const DonateCampaign = () => {
  return (
    <div>
      <Header />
      <section className="donate-page">
        <div className="auto-container">
          <div className="row">
            <div className="col-md-8">
              <div className="page-sec-title ">
                <p className="text-center " style={{ color: "#777" }}>
                  Your contributions are eligible for 50% tax benefit under
                  section 80G
                </p>
              </div>

              <div className="donate-now-form mt-2">
                <CustomDonateForm />
              </div>
            </div>
            <div className="col-md-4">
              <div className="mt-5">
                <div className="">
                  <Scanner />
                  <OfflineDonation />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DonateCampaign);
