import React from "react";
import { Link } from "react-router-dom";
import Spinner from "../components/layout/Spinner";
import Slider from "react-slick";

function Banner({ banners, loading }) {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      {!loading ? (
        <>
          {banners && (
            <Slider {...settings}>
              {banners.map((banner) => {
                return (
                  <div key={`banner-${banner.id}`}>
                    <section className="banners-s">
                      <div
                        className="banner"
                        style={{
                          backgroundImage: `url(${banner.featured_image})`,
                        }}
                      >
                        <div className="overlay" />
                        <div className="auto-container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="banner-content">
                                <span className="btn-save">Save the quest</span>
                                <h1>{banner.heading}</h1>
                                <p>{banner.description}</p>
                                <Link
                                  to="/donate-now"
                                  className="theme-btn btn-style-one"
                                >
                                  <span className="btn-title">Donate Now</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                );
              })}
            </Slider>
          )}
        </>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default Banner;
