import {
  GET_FAQS,
  ADD_FAQ,
  EDIT_FAQ,
  GET_FAQ,
  RESET_FAQ,
  FAQS_ERROR,
} from "../types/faq_type";

const initialState = {
  faqs: null,
  faq: null,
  total_faqs: 0,
  loading: true,
  error: {},
  faq_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FAQS:
      return {
        ...state,
        faqs: payload,
        loading: false,
        error: {},
      };

    case RESET_FAQ:
      return {
        ...state,
        faq: null,
        loading: true,
        error: {},
      };

    case ADD_FAQ:
      return {
        ...state,
        faq_message: payload,
        loading: false,
        error: {},
      };
    case GET_FAQ:
      return {
        ...state,
        faq: payload,
        loading: false,
        error: {},
      };
    case EDIT_FAQ:
      return {
        ...state,
        faq_message: payload,
        loading: false,
        error: {},
      };

    case FAQS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
