import React from "react";
import CauseSingleCard from "./common/CauseSingleCard";
import Spinner from "./layout/Spinner";
import { Link } from "react-router-dom";
function CauseSectionComponent({ loading, causes, hide }) {
  return (
    <div>
      <section className="causes cau ptb-80">
        <div className="auto-container">
          <div className="sec-title centered mb-5">
            <h2>
              Our Causes{" "}
              {!hide && (
                <span>
                  <Link to="/causes"> View All </Link>
                </span>
              )}
            </h2>
            <div className="text">We believe what we achieve</div>
          </div>
          {!loading ? (
            <div className="row clearfix">
              {causes &&
                causes.map((cause) => {
                  return <CauseSingleCard cause={cause} />;
                })}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </section>
    </div>
  );
}

export default CauseSectionComponent;
