import React from "react";
import TestimonialCard from "./common/TestimonialCard";
import Spinner from "./layout/Spinner";
import Slider from "react-slick";
function TestimonialSectionComponent({ testimonials, loading }) {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <section className="voluntter-test">
        <div
          className="pattern-image"
          style={{ background: 'url("assets/images/bg-pattern-2.jpg")' }}
        />
        {/* <div class="sec-title centered mt-3 mb-5">
              <h2>Testimonial</h2>
              <div class="text">We believe what we achieve</div>
          </div> */}
        <div className="voluntter-request">
          {!loading ? (
            <div className="auto-container">
              <Slider {...settings}>
                {testimonials &&
                  testimonials.map((testimonial) => {
                    return (
                      <TestimonialCard
                        key={testimonial.id}
                        image={"assets/images/user.png"}
                        paragraph={testimonial.testimonial}
                        name={testimonial.user}
                      />
                    );
                  })}
              </Slider>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </section>
    </div>
  );
}

export default TestimonialSectionComponent;
