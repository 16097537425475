import React from "react";

function OfflineDonation() {
  return (
    <div>
      <div className="offline-donation">
        <div className="offlie ">
          <h2> Offline Donation</h2>
          <p>
            Our Bank details to make donation from net-banking / Internet
            Banking.
          </p>
          <div className="offline-ban">
            <div>
              <p>Bank Name: </p>
            </div>
            <div className="red-1">
              <p>State Bank of India</p>
            </div>
          </div>
          <div className="offline-ban">
            <div>
              <p>Beneficiary/Name : </p>
            </div>
            <div className="red-1">
              <p>Save the Quest</p>
            </div>
          </div>
          <div className="offline-ban">
            <div>
              <p>Type of Account : </p>
            </div>
            <div className="red-1">
              <p> Current</p>
            </div>
          </div>
          <div className="offline-ban">
            <div>
              <p>Account Number :</p>
            </div>
            <div className="red-1">
              <p> 33269236877</p>
            </div>
          </div>
          <div className="offline-ban">
            <div style={{ flex: 1 }}>
              <p>Branch :</p>
            </div>
            <div className="red-1" style={{ flex: 4 }}>
              <p>
                {" "}
                B-1/A-19, MOHANCO-OPERATIVE ESTATE, MATHURA ROAD,NEW DELHI -
                110044
              </p>
            </div>
          </div>
          <div className="offline-ban">
            <div>
              <p>IFSC Code :</p>
            </div>
            <div className="red-1">
              <p> SBIN0016106</p>
            </div>
          </div>
          <div className="offline-ban">
            <div>
              <p>MICR Code : </p>
            </div>
            <div className="red-1">
              <p> 110002388</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfflineDonation;
