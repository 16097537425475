import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAwardsandmedias } from "../../store/actions/awardandmedias_action";
import Slider from "react-slick";
const MediaCoverageComponent = ({ getAwardsandmedias, media, hideLink }) => {
  useEffect(() => {
    if (!media.awardandmedias) {
      getAwardsandmedias({});
    }
  }, [media]);
  var settings = {
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div className="single-scribe-box mt-3">
      <Slider {...settings}>
        {media.awardandmedias &&
          media.awardandmedias.map((item) => {
            return (
              <div>
                <a href={hideLink ? "#medialink" : item.link} target={"_self"}>
                  <img src={item.featured_image} />
                  <div className="text-center mt-2">
                    {" "}
                    <h5> {item.name} </h5>
                  </div>
                </a>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  media: state.media,
});

const mapDispatchToProps = {
  getAwardsandmedias,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaCoverageComponent);
