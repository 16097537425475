import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/css/wysiwyg.css";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import store from "./store/store";
import Home from "./containers/home/Home";
import { loadUser } from "./store/actions/auth";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import { Accessibility } from "accessibility/src/main";
import CustomDonateForm from "./components/campaign/CustomDonateForm";
import DonateCampaign from "./containers/donate/DonateCampaign";
const RegisterAsStudent = lazy(() =>
  import("./containers/students/RegisterAsStudent")
);
const RegisterAsVolunteer = lazy(() =>
  import("./containers/volunteers/RegisterAsVolunteer")
);
const About = lazy(() => import("./containers/about/About"));
const Contact = lazy(() => import("./containers/contact/ContactUs"));
const Collaboration = lazy(() => import("./containers/contact/Collaborate"));
const ViewCampaign = lazy(() => import("./containers/campaigns/ViewCampaign"));
const ViewProject = lazy(() => import("./containers/projects/ViewProject"));
const Faq = lazy(() => import("./containers/faqs/Faq"));
const Login = lazy(() => import("./containers/login/Login"));
const SignUp = lazy(() => import("./containers/login/SignUp"));
const ForgetPassword = lazy(() => import("./containers/login/ForgetPassword"));
const ResetPassword = lazy(() => import("./containers/login/ResetPassword"));
const MyAccount = lazy(() => import("./containers/my-account/MyAccount"));
const RequestForVolunteer = lazy(() =>
  import("./containers/get-help/RequestForVolunteer")
);
const RequestForScribe = lazy(() =>
  import("./containers/get-help/RequestForScribe")
);
const MakeAWish = lazy(() => import("./containers/get-help/MakeAWish"));
// Scribe Requests
const ScribeRequests = lazy(() =>
  import("./containers/scribe-requests/ScribeRequests")
);
const SingleScribeRequest = lazy(() =>
  import("./containers/scribe-requests/SingleScribeRequest")
);
const EditScribeRequest = lazy(() =>
  import("./containers/scribe-requests/EditScribeRequest")
);
// Volunteer Requests
const AllVolunteerRequests = lazy(() =>
  import("./containers/volunteer-requests/AllVolunteerRequests")
);
const SingleVolunteerRequest = lazy(() =>
  import("./containers/volunteer-requests/SingleVolunteerRequest")
);
const EditVolunteerRequest = lazy(() =>
  import("./containers/volunteer-requests/EditVolunteerRequest")
);
// Wishes
const AllWishes = lazy(() => import("./containers/wishes/AllWishes"));
const SingleWish = lazy(() => import("./containers/wishes/SingleWish"));
// Campaigns
const AllCampaigns = lazy(() => import("./containers/campaigns/AllCampaigns"));
// Causes
const AllCauses = lazy(() => import("./containers/causes/AllCauses"));
const SingleCause = lazy(() => import("./containers/causes/SingleCause"));
// Blogs
const AllBlogs = lazy(() => import("./containers/blogs/AllBlogs"));
const SingleBlog = lazy(() => import("./containers/blogs/SingleBlog"));
// Volunteers
const AllVolunteers = lazy(() =>
  import("./containers/volunteers/AllVolunteers")
);
const SingleVolunteer = lazy(() =>
  import("./containers/volunteers/SingleVolunteer")
);
const DonationPage = lazy(() => import("./containers/donate/DonationPage"));
const AwardsMedia = lazy(() => import("./containers/awards/AwardsMedia"));
const Gallery = lazy(() => import("./containers/gallery/Gallery"));
const SingleGallery = lazy(() => import("./containers/gallery/SingleGallery"));
const ThankYou = lazy(() => import("./containers/donate/ThankYou"));
const Terms = lazy(() => import("./containers/policy/Terms"));
const Privacy = lazy(() => import("./containers/policy/Privacy"));
const Cancellation = lazy(() => import("./containers/policy/Cancellation"));

const renderLoader = () => (
  <img
    src="/images/spinner.gif"
    style={{ width: "80px", margin: "auto", display: "block" }}
    alt="Loading..."
  />
);
function App() {
  useEffect(() => {
    store.dispatch(loadUser());

    window.addEventListener(
      "load",
      function () {
        var options = {
          icon: {
            position: {
              bottom: { size: 50, units: "px" },

              left: { size: 0, units: "px" },

              type: "fixed",
            },
          },
        };
        new Accessibility(options);
      },
      false
    );
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={renderLoader()}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/register-as-a-student"
              component={RegisterAsStudent}
            />
            <BeforeLoginRoutes exact path="/login" component={Login} />
            <BeforeLoginRoutes
              exact
              path="/forget-password"
              component={ForgetPassword}
            />
            <BeforeLoginRoutes
              exact
              path="/reset-password"
              component={ResetPassword}
            />
            <BeforeLoginRoutes exact path="/signup" component={SignUp} />
            <Route
              exact
              path="/register-as-volunteer"
              component={RegisterAsVolunteer}
            />
            <PrivateRoutes exact path="/my-account" component={MyAccount} />
            <PrivateRoutes
              exact
              path="/add-volunteer-request"
              component={RequestForVolunteer}
            />
            <PrivateRoutes
              exact
              path="/add-scribe-request"
              component={RequestForScribe}
            />
            <PrivateRoutes
              exact
              path="/add-wish-request"
              component={MakeAWish}
            />
            <Route exact path="/scribe-requests" component={ScribeRequests} />
            <Route
              exact
              path="/scribe-requests/:id"
              component={SingleScribeRequest}
            />
            <PrivateRoutes
              exact
              path="/scribe-requests/:id/edit"
              component={EditScribeRequest}
            />
            <Route
              exact
              path="/volunteer-requests"
              component={AllVolunteerRequests}
            />
            <Route
              exact
              path="/volunteer-requests/:id"
              component={SingleVolunteerRequest}
            />
            <PrivateRoutes
              exact
              path="/volunteer-requests/:id/edit"
              component={EditVolunteerRequest}
            />
            <Route exact path="/wishes" component={AllWishes} />
            <Route exact path="/wishes/:slug" component={SingleWish} />
            <Route exact path="/blogs" component={AllBlogs} />
            <Route exact path="/blogs/:slug" component={SingleBlog} />
            <Route exact path="/campaigns" component={AllCampaigns} />
            <Route exact path="/campaigns/:slug" component={ViewCampaign} />
            <Route exact path="/campaings/:slug" component={ViewCampaign} />
            <Route exact path="/volunteers" component={AllVolunteers} />
            <Route exact path="/volunteers/:id" component={SingleVolunteer} />
            <Route exact path="/causes" component={AllCauses} />
            <Route exact path="/causes/:slug" component={SingleCause} />
            <Route exact path="/projects/:slug" component={ViewProject} />
            <Route exact path="/thank-you" component={ThankYou} />
            <Route exact path="/awards-and-media" component={AwardsMedia} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/gallery/:id" component={SingleGallery} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/contact-us" component={Contact} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/cancellation" component={Cancellation} />
            <Route exact path="/collaboration" component={Collaboration} />
            <Route exact path="/donate-now" component={DonationPage} />
            <Route
              exact
              path="/donate-campaign/:slug"
              component={DonateCampaign}
            />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
