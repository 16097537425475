import {
  GET_AWARDSANDMEDIAS,
  ADD_AWARDSANDMEDIA,
  EDIT_AWARDSANDMEDIA,
  GET_AWARDSANDMEDIA,
  RESET_AWARDSANDMEDIA,
  AWARDSANDMEDIAS_ERROR,
} from "../types/awardandmedia_type";

const initialState = {
  awardandmedias: null,
  awardandmedia: null,
  total_awardandmedias: 0,
  loading: true,
  error: {},
  awardandmedia_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AWARDSANDMEDIAS:
      return {
        ...state,
        awardandmedias: payload,
        loading: false,
        error: {},
      };

    case RESET_AWARDSANDMEDIA:
      return {
        ...state,
        awardandmedia: null,
        loading: true,
        error: {},
      };

    case ADD_AWARDSANDMEDIA:
      return {
        ...state,
        awardandmedia_message: payload,
        loading: false,
        error: {},
      };
    case GET_AWARDSANDMEDIA:
      return {
        ...state,
        awardandmedia: payload,
        loading: false,
        error: {},
      };
    case EDIT_AWARDSANDMEDIA:
      return {
        ...state,
        awardandmedia_message: payload,
        loading: false,
        error: {},
      };

    case AWARDSANDMEDIAS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
