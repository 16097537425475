import { combineReducers } from "redux";
import auth from "./auth_reducer";
import alert from "./alert_reducer";
import blog from "./blog_reducer";
import page from "./page_reducer";
import newsletter from "./newsletter_reducer";
import project from "./project_reducer";
import banner from "./banner_reducer";
import mobilebanner from "./mobilebanner_reducer";
import testimonial from "./testimonial_reducer";
import faq from "./faq_reducer";
import contact from "./contact_reducer";
import donation from "./donation_reducer";
import cause from "./cause_reducer";
import product from "./product_reducer";
import volunteer from "./volunteer_reducer";
import wish from "./wish_reducer";
import scriberequest from "./scriberequest_reducer";
import volunteerrequest from "./volunteerrequest_reducer";
import benificiary from "./benificiary_reducer";
import profile from "./profile_reducer";
import media from "./awardandmedia_reducer";
import event from "./event_reducer";
import collaboration from "./collaboration_reducer";
import aid from "./aid_reducer";
export default combineReducers({
  auth,
  blog,
  alert,
  page,
  newsletter,
  project,
  banner,
  mobilebanner,
  testimonial,
  faq,
  contact,
  donation,
  cause,
  product,
  volunteer,
  wish,
  scriberequest,
  volunteerrequest,
  benificiary,
  profile,
  media,
  event,
  collaboration,
  aid,
});
