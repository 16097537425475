import React from "react";

function Scanner() {
  return (
    <div className="mt-3">
      <div className="single-scribe-box ">
        <div className="offlie text-center mb-5">
          <h2 style={{ fontSize: 18 }}>Scan &amp; donate with any app</h2>
          <div className="offlie-img">
            <img src="/images/scanner.jpeg" />
          </div>
          <div className="offline-bhim">
            <div>
              <img src="/images/bhim.png" />
            </div>
            <div>
              <img src="/images/pay.png" />
            </div>
            <div>
              <img src="/images/paytm.png" />
            </div>
            <div>
              <img src="/images/g-pay.png" />
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default Scanner;
