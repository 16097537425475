import React from "react";
import WishRequestCard from "./common/WishRequestCard";
import { wishes } from "../models/wishes";
import Spinner from "./layout/Spinner";
import { Link } from "react-router-dom";
function WishRequestComponent({ wishes, loading, hide }) {
  return (
    <div>
      {!loading ? (
        <section className="causes ptb-80">
          <div className="auto-container">
            <div className="sec-title centered mb-5">
              <h2>
                Wish Requests{" "}
                {!hide && (
                  <span>
                    <Link to="/wishes"> View All </Link>
                  </span>
                )}
              </h2>
              <div className="text">We believe what we achieve</div>
            </div>
            <div className="row clearfix">
              {wishes &&
                wishes.map((wish) => {
                  if (wish.status == "ACTIVE") {
                    return <WishRequestCard wish={wish} />;
                  }
                })}
            </div>
          </div>
        </section>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default WishRequestComponent;
