import React from "react";
import { Link } from "react-router-dom";

function VolunteerRequestCard({ heading, location, month, date, status, id }) {
  return (
    <div className="col-md-6">
      <div className="icon-with-request">
        <div className="row">
          <div className="col-md-12 ">
            <p
              style={{
                textAlign: "right",
                color: "#dc4549",
                fontWeight: "bold",
              }}
            >
              {status}
            </p>
          </div>
          <div className="col-md-2">
            <div className="request-icon">
              <img src="/assets/images/volunteer.png" />
            </div>
          </div>
          <div className="col-md-10">
            <div className="scribe-req-text">
              <h2>{heading}</h2>
            </div>
            <div className="req-icon">
              <div>
                {" "}
                <p>
                  {" "}
                  <i className="fa fa-map-marker" />
                  {location}
                </p>
              </div>
              <div>
                {" "}
                <p>
                  {" "}
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  {month}
                </p>
              </div>
              <div>
                {" "}
                <p>
                  <i className="fa fa-calendar" aria-hidden="true" /> {date}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-2">
          <Link
            to={`/volunteer-requests/${id}`}
            className="theme-btn btn-style-two "
          >
            <span className="btn-title">View Request</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VolunteerRequestCard;
