import React from 'react'
import { Link } from 'react-router-dom'

function ActionSection() {
  return (
    <div>
      <section className="become-partner">
        <div
          className="pattern-image"
          style={{ background: 'url("assets/images/bg-pattern-1.png")' }}
        />
        <div className="auto-container">
          <div className="sec-become">
            <h2>
              Become a Smiling Partner today &amp; you can help children just
              like many!
            </h2>
          </div>
          <div className="row clearfix">
            <div className="featured-box col-md-4">
              <div className="inner-box bank">
                <div className="icon-box">
                  <img src="assets/images/charity.png" />
                </div>
                <div className="sub-text">
                  <Link to="/signup">JOIN STQ VOLUNTEER BANK</Link>
                  <p>
                    Let’s fulfil someone’s small dream together! Make A Wish is
                    a new initiative started by Save The Quest to help and serve
                    underprivileged and differently-abled children of our
                    country.
                  </p>
                </div>
              </div>
            </div>
            <div className="featured-box col-md-4">
              <div className="inner-box bank ">
                <div className="icon-box ">
                  <img src="assets/images/make-a-wish.png" />
                </div>
                <div className="sub-text">
                  <Link to="/add-wish-request">Make A Wish</Link>
                  <p>
                    Let’s fulfil someone’s small dream together! Make A Wish is
                    a new initiative started by Save The Quest to help and serve
                    underprivileged and differently-abled children of our
                    country.
                  </p>
                </div>
              </div>
            </div>
            <div className="featured-box col-md-4">
              <div className="inner-box bank">
                <div className="icon-box">
                  <img src="assets/images/video-conference.png" />
                </div>
                <div className="sub-text">
                  <Link to="/add-volunteer-request">Request for Volunteer</Link>
                  <p>
                    Save The Quest has always believed in authorizing and
                    enabling the youth and is heavily bestowed in channelizing
                    their strength to encourage development across the nation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ActionSection
