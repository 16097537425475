import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MobileNav from './MobileNav'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/auth'
import { getUserProfile } from '../../store/actions/profile_action'
function Header({
  logout,
  getUserProfile,
  auth: { isAuthenticated, user },
  profile: { myprofile },
}) {
  const [activeUser, setActiveUser] = useState(null)
  useEffect(() => {
    if (user) {
      getUserProfile({ termField: 'user', term: user.uid })
    }
  }, [user])
  useEffect(() => {
    if (isAuthenticated && myprofile && myprofile.length > 0) {
      setActiveUser(myprofile[0])
    } else {
      setActiveUser(null)
    }
  }, [myprofile, isAuthenticated])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [openProjectMenu, setOpenProjectMenu] = useState(false)
  const [openContactMenu, setopenContactMenu] = useState(false)
  const [opengethelp, setopengethelp] = useState(false)
  const [openVolunteer, setopenVolunteer] = useState(false)
  const [openMobile, setopenMobile] = useState(false)

  return (
    <div>
      <header className="main-header">
        <div className="header-top">
          <div className="container">
            <div className="row clearfix">
              <div className="top-left col-md-5 col-sm-6">
                <ul>
                  <li>Welcome To Save The Quest</li>
                </ul>
              </div>
              <div className="login">
                <div className="login-sign-btn">
                  {!isAuthenticated ? (
                    <Link to="/login">Login / Signup</Link>
                  ) : (
                    <Link to="/my-account">My Account</Link>
                  )}
                </div>
              </div>
              {/* mobile header */}
              <MobileNav isAuthenticated={isAuthenticated} logout={logout} />
              {/* end mobile header */}
              <div className="top-right col-md-7">
                <div className="question">
                  <a>You have any question? </a>
                  <a href="tel:8585907442">8585907442</a>
                </div>
                <div className="question-2">
                  {!isAuthenticated ? (
                    <>
                      <Link to="/login">Login </Link>
                      <Link to="/signup">Signup</Link>
                    </>
                  ) : (
                    <>
                      <Link to="/my-account">My Account</Link>
                      <a onClick={() => logout()}>Logout</a>
                    </>
                  )}
                </div>
                <ul className="social-box">
                  <li>
                    <a
                      href="https://www.facebook.com/SaveTheQuest/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/savethequest" target="_blank">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/savethequest/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-upper">
          <div className="container">
            <div className="clearfix">
              <div className="row">
                <div className="col-md-2">
                  <div className="pull-left logo-box">
                    <div className="logo">
                      <Link to="/">
                        <img src="/assets/images/logo.png" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="pull-right upper-right">
                    <div className="info-outer clearfix">
                      <div className="nav">
                        <ul>
                          <li className="active">
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="/about-us">About Us</Link>
                          </li>
                          <li
                            onMouseEnter={() => setOpenProjectMenu(true)}
                            onMouseLeave={() => setOpenProjectMenu(false)}
                          >
                            <a href="#">
                              Projects <i className="fa fa-chevron-down" />
                            </a>
                            <div
                              className="dropdown-mobile"
                              style={{
                                display: openProjectMenu ? 'block' : 'none',
                              }}
                            >
                              <ul>
                                <li>
                                  <Link to="/projects/taare">TAARE</Link>
                                </li>
                                <li>
                                  <Link to="/projects/eye-quest">
                                    EYE QUEST
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/projects/quest-public-school">
                                    QUEST PUBLIC SCHOOL
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/projects/lal-batti">
                                    LAL BATTI
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/projects/panchi">PANCHI</Link>
                                </li>
                                <li>
                                  <Link to="/projects/khillona"> KHILLONA</Link>
                                </li>

                                {/* <li>
                                  <Link to="/causes"> CURE ALL</Link>
                                </li> */}
                                <li>
                                  <Link to="/campaigns">CAMPAIGNS</Link>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li
                            onMouseEnter={() => setopenContactMenu(true)}
                            onMouseLeave={() => setopenContactMenu(false)}
                          >
                            <a href="#">
                              Contact <i className="fa fa-chevron-down" />
                            </a>
                            <div
                              className="dropdown-mobile"
                              style={{
                                display: openContactMenu ? 'block' : 'none',
                              }}
                            >
                              <ul>
                                <li>
                                  <Link to="/contact-us"> Contact Us </Link>
                                </li>
                                <li>
                                  <Link to="/collaboration"> Collaborate </Link>
                                </li>
                              </ul>
                            </div>
                          </li>
                          {activeUser ? (
                            <>
                              {activeUser.profile_type &&
                                activeUser.profile_type === 'beneficiary' && (
                                  <li
                                    onMouseEnter={() => setopengethelp(true)}
                                    onMouseLeave={() => setopengethelp(false)}
                                  >
                                    <a href="#">
                                      Get Help{' '}
                                      <i className="fa fa-chevron-down" />
                                    </a>
                                    <div
                                      className="dropdown-mobile"
                                      style={{
                                        display: opengethelp ? 'block' : 'none',
                                      }}
                                    >
                                      <ul>
                                        <li>
                                          <Link to="/add-volunteer-request">
                                            Request for Volunteer
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/add-scribe-request">
                                            {' '}
                                            Request for Scribe
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/add-wish-request">
                                            {' '}
                                            Make a Wish
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                )}
                            </>
                          ) : (
                            <li
                              onMouseEnter={() => setopengethelp(true)}
                              onMouseLeave={() => setopengethelp(false)}
                            >
                              <a href="#">
                                Get Help <i className="fa fa-chevron-down" />
                              </a>
                              <div
                                className="dropdown-mobile"
                                style={{
                                  display: opengethelp ? 'block' : 'none',
                                }}
                              >
                                <ul>
                                  <li>
                                    <Link to="/add-volunteer-request">
                                      Request for Volunteer
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/add-scribe-request">
                                      {' '}
                                      Request for Scribe
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/add-wish-request">
                                      {' '}
                                      Make a Wish
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          )}
                          {activeUser ? (
                            <>
                              {activeUser.profile_type &&
                                activeUser.profile_type === 'volunteer' && (
                                  <li
                                    onMouseEnter={() => setopenVolunteer(true)}
                                    onMouseLeave={() => setopenVolunteer(false)}
                                  >
                                    <a href="#">
                                      Volunteer{' '}
                                      <i className="fa fa-chevron-down" />{' '}
                                    </a>
                                    <div
                                      className="dropdown-mobile"
                                      style={{
                                        display: openVolunteer
                                          ? 'block'
                                          : 'none',
                                      }}
                                    >
                                      <ul>
                                        <li>
                                          <Link to="/scribe-requests">
                                            {' '}
                                            Help Scribe Now{' '}
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/volunteer-requests">
                                            {' '}
                                            Help Now{' '}
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/wishes">
                                            Fullfil A Wish
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                )}
                            </>
                          ) : (
                            <li
                              onMouseEnter={() => setopenVolunteer(true)}
                              onMouseLeave={() => setopenVolunteer(false)}
                            >
                              <a href="#">
                                Volunteer <i className="fa fa-chevron-down" />{' '}
                              </a>
                              <div
                                className="dropdown-mobile"
                                style={{
                                  display: openVolunteer ? 'block' : 'none',
                                }}
                              >
                                <ul>
                                  <li>
                                    <Link to="/scribe-requests">
                                      {' '}
                                      Help Scribe Now{' '}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/volunteer-requests">
                                      {' '}
                                      Help Now{' '}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/wishes">Fullfil A Wish</Link>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          )}

                          <li>
                            <Link to="/awards-and-media">Awards / Media </Link>
                          </li>
                          <li>
                            <Link to="/gallery">Gallery </Link>
                          </li>
                        </ul>
                        <div className="mt-2">
                          <Link
                            to="/donate-now"
                            className="theme-btn btn-style-one"
                          >
                            <span className="btn-title">Donate Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
})

const mapDispatchToProps = { logout, getUserProfile }

export default connect(mapStateToProps, mapDispatchToProps)(Header)
