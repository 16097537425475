import React from "react";
import AwardsCard from "./common/AwardsCard";
import MediaCoverageCard from "./common/MediaCoverageCard";
import { FacebookProvider, Page } from "react-facebook";
import MediaCoverageComponent from "./singlepage/MediaCoverageComponent";

function MediaCoponent() {
  return (
    <div>
      <section className="voluntter ">
        <div
          className="pattern-image"
          style={{ background: 'url("assets/images/bg-pattern-1.png")' }}
        />
        <div className="voluntter-request">
          <div className="auto-container">
            <div className="media-awards">
              <div className="row">
                <div className="col-md-4">
                  <div className="post-media-title">
                    <h2>Facebook Posts</h2>
                  </div>
                  <div className="post-1">
                    <FacebookProvider appId="1264414050559265">
                      <Page
                        href="https://www.facebook.com/SaveTheQuest"
                        tabs="timeline"
                        style={{ width: "98%" }}
                      />
                    </FacebookProvider>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="post-media-title">
                    <h2>Media Coverage</h2>
                  </div>
                  <MediaCoverageComponent />
                </div>
                <AwardsCard image={"assets/images/news.png"} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MediaCoponent;
