import {
  GET_VOLUNTEERREQUESTS,
  ADD_VOLUNTEERREQUEST,
  EDIT_VOLUNTEERREQUEST,
  GET_VOLUNTEERREQUEST,
  RESET_VOLUNTEERREQUEST,
  VOLUNTEERREQUESTS_ERROR,
} from "../types/volunteerrequest_type";

const initialState = {
  volunteerrequests: null,
  volunteerrequest: null,
  total_volunteerrequests: 0,
  loading: true,
  error: {},
  volunteerrequest_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VOLUNTEERREQUESTS:
      return {
        ...state,
        volunteerrequests: payload,
        loading: false,
        error: {},
      };

    case RESET_VOLUNTEERREQUEST:
      return {
        ...state,
        volunteerrequest: null,
        loading: true,
        error: {},
      };

    case ADD_VOLUNTEERREQUEST:
      return {
        ...state,
        volunteerrequest_message: payload,
        loading: false,
        error: {},
      };
    case GET_VOLUNTEERREQUEST:
      return {
        ...state,
        volunteerrequest: payload,
        loading: false,
        error: {},
      };
    case EDIT_VOLUNTEERREQUEST:
      return {
        ...state,
        volunteerrequest_message: payload,
        loading: false,
        error: {},
      };

    case VOLUNTEERREQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
