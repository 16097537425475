import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Header from '../../components/common/Header'
import Banner from '../../components/Banner'
import FeatureSection from '../../components/FeatureSection'
import AboutSection from '../../components/AboutSection'
import ActionSection from '../../components/ActionSection'
import CampaignComponent from '../../components/CampaignComponent'
import Footer from '../../components/common/Footer'
import ScribeRequestsComponent from '../../components/ScribeRequestsComponent'
import WishActionComponent from '../../components/WishActionComponent'
import VolunteerRequestComponent from '../../components/VolunteerRequestComponent'
import WishRequestComponent from '../../components/WishRequestComponent'
import FunfactComponent from '../../components/FunfactComponent'
import CauseSectionComponent from '../../components/CauseSectionComponent'
import Volunteer from '../../components/Volunteer'
import TestimonialSectionComponent from '../../components/TestimonialSectionComponent'
import BlogsectionComponent from '../../components/BlogsectionComponent'
import MediaCoponent from '../../components/MediaCoponent'
import { getBanners } from '../../store/actions/banners_action'
import { getProducts } from '../../store/actions/products_action'
import { getWishs } from '../../store/actions/wishs_action'
import { getCauses } from '../../store/actions/causes_action'
import { getTestimonials } from '../../store/actions/testimonials_action'
import { getBlogs } from '../../store/actions/blogs_action'
import { getScriberequests } from '../../store/actions/scriberequests_action'
import { getVolunteerrequests } from '../../store/actions/volunteerrequests_action'
import { getVolunteers } from '../../store/actions/volunteers_action'
import { useEffect } from 'react'
import AlertBox from '../../components/common/AlertBox'
const Home = ({
  getCauses,
  getWishs,
  getBanners,
  getProducts,
  getTestimonials,
  getBlogs,
  getScriberequests,
  getVolunteerrequests,
  getVolunteers,
  banner,
  product,
  wish,
  cause,
  testimonial,
  blog,
  scriberequest,
  volunteerrequest,
  volunteer,
}) => {
  useEffect(() => {
    getBanners({})
    getProducts({})
    getWishs({})
    getCauses({})
    getTestimonials({})
    getBlogs({})
    getScriberequests({})
    getVolunteerrequests({})
    getVolunteers({})
  }, [])
  return (
    <div>
      <Helmet>
        <title> Save The Quest</title>
      </Helmet>
      {/* header end */}
      <div>
        <Header />
        <Banner banners={banner.banners} loading={banner.loading} />
        <FeatureSection />
        <AboutSection />
        <ActionSection />
        <CampaignComponent
          products={product.products}
          loading={product.loading}
        />
        <ScribeRequestsComponent
          scriberequests={scriberequest.scriberequests}
          loading={scriberequest.loading}
        />
        <WishActionComponent />
        <VolunteerRequestComponent
          volunteer_requests={volunteerrequest.volunteerrequests}
          loading={volunteerrequest.loading}
        />
        <WishRequestComponent wishes={wish.wishs} loading={wish.loading} />
        <FunfactComponent />
        {/* <CauseSectionComponent causes={cause.causes} loading={cause.loading} /> */}
        <Volunteer
          volunteers={volunteer.volunteers}
          loading={volunteer.loading}
        />
        <TestimonialSectionComponent
          testimonials={testimonial.testimonials}
          loading={testimonial.loading}
        />
        <BlogsectionComponent blogs={blog.blogs} loading={blog.loading} />
        <MediaCoponent />
        <AlertBox />
        <Footer />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  banner: state.banner,
  product: state.product,
  wish: state.wish,
  cause: state.cause,
  testimonial: state.testimonial,
  blog: state.blog,
  scriberequest: state.scriberequest,
  volunteerrequest: state.volunteerrequest,
  volunteer: state.volunteer,
})

const mapDispatchToProps = {
  getBanners,
  getProducts,
  getWishs,
  getCauses,
  getTestimonials,
  getBlogs,
  getScriberequests,
  getVolunteerrequests,
  getVolunteers,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
