import React from 'react'

function Raise({raiseamount}) {
    return (
        <div>
            <div className="raised">
                <i className="fa fa-bar-chart" aria-hidden="true" />
                <span>Raised:</span>
                <strong>{raiseamount}</strong>
              </div>
        </div>
    )
}

export default Raise
