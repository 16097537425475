import React from "react";
import { Link } from "react-router-dom";

function WishActionComponent() {
  return (
    <div>
      <section
        className="call-to-action"
        style={{
          background: 'url("assets/images/testimonial.jpg")',
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="auto-container">
          <div className="call-content">
            <h2>No One has ever become poor by giving</h2>
            <p>
              With the help of kind &amp; generous donors and over hundreds of
              volunteers, Save The Quest’s Make A Wish initiative has
              collectively granted approximately more than 120 wishes in India.
            </p>
            <div>
              {" "}
              <Link to="/wishes" className="theme-btn btn-style-one">
                <span className="btn-title">Full Fill A Wish</span>
              </Link>
              <Link
                to="/add-wish-request"
                className="theme-btn btn-style-two"
                style={{ marginLeft: 20 }}
              >
                <span className="btn-title">Make a Wish</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WishActionComponent;
