import {
  GET_BENIFICIARYS,
  ADD_BENIFICIARY,
  EDIT_BENIFICIARY,
  GET_BENIFICIARY,
  RESET_BENIFICIARY,
  BENIFICIARYS_ERROR,
} from "../types/benificiary_type";

const initialState = {
  benificiarys: null,
  benificiary: null,
  total_benificiarys: 0,
  loading: true,
  error: {},
  benificiary_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BENIFICIARYS:
      return {
        ...state,
        benificiarys: payload,
        loading: false,
        error: {},
      };

    case RESET_BENIFICIARY:
      return {
        ...state,
        benificiary: null,
        loading: true,
        error: {},
      };

    case ADD_BENIFICIARY:
      return {
        ...state,
        benificiary_message: payload,
        loading: false,
        error: {},
      };
    case GET_BENIFICIARY:
      return {
        ...state,
        benificiary: payload,
        loading: false,
        error: {},
      };
    case EDIT_BENIFICIARY:
      return {
        ...state,
        benificiary_message: payload,
        loading: false,
        error: {},
      };

    case BENIFICIARYS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
