import {
  GET_AIDS,
  ADD_AID,
  EDIT_AID,
  GET_AID,
  RESET_AID,
  AIDS_ERROR,
} from "../types/aid_type";

const initialState = {
  aids: null,
  aid: null,
  total_aids: 0,
  loading: true,
  error: {},
  aid_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AIDS:
      return {
        ...state,
        aids: payload,
        loading: false,
        error: {},
      };

    case RESET_AID:
      return {
        ...state,
        aid: null,
        loading: true,
        error: {},
      };

    case ADD_AID:
      return {
        ...state,
        aid_message: payload,
        loading: false,
        error: {},
      };
    case GET_AID:
      return {
        ...state,
        aid: payload,
        loading: false,
        error: {},
      };
    case EDIT_AID:
      return {
        ...state,
        aid_message: payload,
        loading: false,
        error: {},
      };

    case AIDS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
