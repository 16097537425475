import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextArea, TextInput } from "../Form/Form";
import axios from "axios";
import { addDonation } from "../../store/actions/donations_action";
function DonationForm({ addDonation }) {
  const donateNow = async ({ name, phone, email, amount }) => {
    const order = await axios.get(
      `https://savethequest.org/razorpay/pay.php?amount=${amount}`
    );
    var options = {
      key: "rzp_live_noQjWbvouhizky",
      amount: amount * 100,
      currency: "INR",
      name: "Save The Quest",
      description: "",
      image: "https://savethequest.org/assets/images/logo.png",
      order_id: order.data,
      handler: async function (response) {
        const postData = {
          name: name,
          phone: phone,
          email: email,
          amount: amount,
          transaction_id: response.razorpay_payment_id,
          project: window.location.pathname,
          referrer: document.referrer,
        };

        await addDonation(postData);
        window.location.href = `/thank-you?amount=${amount}&phone=${phone}&name=${name}&email=${email}&project=${window.location.pathname}&referrer=${document.referrer}`;
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert("Payment Failed Please try again");
    });
    rzp1.open();
  };
  return (
    <div>
      <div className="mt-2">
        {" "}
        <h2>Donation Amount</h2>
      </div>
      <Formik
        initialValues={{
          amount: 0,
          name: "",
          phone: "",
          email: "",
          pan: "",
          address: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          amount: Yup.string().required("Required"),
          phone: Yup.string().required("Required"),
          email: Yup.string().required("Required"),
          pan: Yup.string().required("Required"),
          address: Yup.string().required("Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await donateNow(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => {
          console.log(formik);
          return (
            <Form>
              <div className="select-do ">
                <div className="select-box">
                  <a
                    className={
                      formik.values.amount === 500
                        ? "amountBtn activeDonationBtn"
                        : "amountBtn"
                    }
                    onClick={() => formik.setFieldValue("amount", 500)}
                  >
                    {" "}
                    ₹500{" "}
                  </a>
                </div>
                <div className="select-box">
                  <a
                    className={
                      formik.values.amount === 1000
                        ? "amountBtn activeDonationBtn"
                        : "amountBtn"
                    }
                    onClick={() => formik.setFieldValue("amount", 1000)}
                  >
                    {" "}
                    ₹1000{" "}
                  </a>
                </div>
                <div className="select-box">
                  <a
                    className={
                      formik.values.amount === 1500
                        ? "amountBtn activeDonationBtn"
                        : "amountBtn"
                    }
                    onClick={() => formik.setFieldValue("amount", 1500)}
                  >
                    {" "}
                    ₹1500{" "}
                  </a>
                </div>
                <div className="select-box">
                  <a
                    className={
                      formik.values.amount === 2000
                        ? "amountBtn activeDonationBtn"
                        : "amountBtn"
                    }
                    onClick={() => formik.setFieldValue("amount", 2000)}
                  >
                    {" "}
                    ₹2000{" "}
                  </a>
                </div>
                <div className="form-group">
                  <TextInput
                    label="Other Amount"
                    name="amount"
                    type="number"
                    placeholder="Enter Amount"
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    label="Name"
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    label="Phone"
                    name="phone"
                    type="text"
                    placeholder="Enter Phone"
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="Enter Email"
                  />
                </div>

                <div className="form-group">
                  <TextInput
                    label="Pan"
                    name="pan"
                    type="text"
                    placeholder="Enter Pan"
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    label="Address"
                    name="address"
                    type="text"
                    placeholder="Enter Address"
                  />
                </div>
                <button
                  type="submit"
                  className="theme-btn btn-style-eight mt-3"
                >
                  <span className="btn-title">
                    {" "}
                    {formik.isSubmitting ? "Loading..." : "Donate Now"}{" "}
                  </span>
                </button>
                <div className="text-center">
                  <p>
                    You are donating: <b>₹{formik.values.amount}</b>
                  </p>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  addDonation,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationForm);
