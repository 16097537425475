import React from "react";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
function BlogCard({ image, date, heading, paragraph, link, month }) {
  return (
    <div className="news-block-one col-md-4">
      <div className="blog-inner">
        <Link to={`/blogs/${link}`}>
          <div className="image">
            <img src={image} />
          </div>
        </Link>
        <div className="blog-content">
          <div className="date">
            <span>{date}</span>
            <span> {month} </span>
          </div>
          <div className="post-meta">
            By: <span>Save the Quest</span>
          </div>
          <h4>{heading}</h4>
          <div className="blog-text">
            <p> {paragraph && renderHTML(paragraph)}</p>
          </div>
          <div className="read-more-btn">
            <Link to={`/blogs/${link}`} className="read-more">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
