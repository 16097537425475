import {
  GET_PROFILES,
  ADD_PROFILE,
  EDIT_PROFILE,
  GET_PROFILE,
  RESET_PROFILE,
  PROFILES_ERROR,
  MY_PROFILE,
} from "../types/profile_type";

const initialState = {
  profiles: null,
  profile: null,
  total_profiles: 0,
  loading: true,
  error: {},
  myprofile: null,
  profile_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false,
        error: {},
      };
    case MY_PROFILE:
      return {
        ...state,
        myprofile: payload,
        loading: false,
        error: {},
      };

    case RESET_PROFILE:
      return {
        ...state,
        profile: null,
        loading: true,
        error: {},
      };

    case ADD_PROFILE:
      return {
        ...state,
        profile_message: payload,
        loading: false,
        error: {},
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false,
        error: {},
      };
    case EDIT_PROFILE:
      return {
        ...state,
        profile_message: payload,
        loading: false,
        error: {},
      };

    case PROFILES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
