import React, { useState } from "react";
import { Link } from "react-router-dom";

function MobileNav({ isAuthenticated, logout }) {
  const [openProjectMenu, setOpenProjectMenu] = useState(false);
  const [openContactMenu, setopenContactMenu] = useState(false);
  const [opengethelp, setopengethelp] = useState(false);
  const [openVolunteer, setopenVolunteer] = useState(false);
  const [openMobile, setopenMobile] = useState(false);
  return (
    <div className="mobile-header">
      <div className="mobile-bars" onClick={() => setopenMobile(true)}>
        <i className="fa fa-bars"></i>
      </div>
      <div
        className="mobile-dropdown"
        style={{ display: openMobile ? "block" : "none" }}
      >
        <div className="nav-wrapper">
          <div className="nav-content">
            <div className="mobile-fl">
              <div class="logo">
                <Link href="#">
                  <img src="/assets/images/logo.png"></img>
                </Link>
              </div>
              <div
                className="text-left close"
                onClick={() => setopenMobile(false)}
              >
                <i className="fa fa-close"></i>
              </div>
            </div>

            <ul className="role">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li
                onClick={() => {
                  setOpenProjectMenu(!openProjectMenu);
                  setopenContactMenu(false);
                  setopengethelp(false);
                  setopenContactMenu(false);
                  setopenVolunteer(false);
                }}
              >
                <a href="#">
                  Projects <i className="fa fa-chevron-down" />
                </a>
                <div
                  className="dropdown-mobile"
                  style={{ display: openProjectMenu ? "block" : "none" }}
                >
                  <ul>
                    <li>
                      <Link to="/projects/taare">TAARE</Link>
                    </li>
                    <li>
                      <Link to="/projects/eye-quest">EYE QUEST</Link>
                    </li>
                    <li>
                      <Link to="/projects/quest-public-school">
                        QUEST PUBLIC SCHOOL
                      </Link>
                    </li>
                    <li>
                      <Link to="/projects/lal-batti">LAL BATTI</Link>
                    </li>
                    <li>
                      <Link to="/projects/panchi">PANCHI</Link>
                    </li>
                    <li>
                      <Link to="/projects/khillona"> KHILLONA</Link>
                    </li>

                    <li>
                      <Link to="/causes"> CURE ALL</Link>
                    </li>
                    <li>
                      <Link to="/campaigns">CAMPAIGNS</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                onClick={() => {
                  setopenContactMenu(!openContactMenu);
                  setOpenProjectMenu(false);
                  setopenVolunteer(false);
                  setopengethelp(false);
                }}
              >
                <a href="#">
                  Contact <i className="fa fa-chevron-down" />
                </a>
                <div
                  className="dropdown-mobile"
                  style={{ display: openContactMenu ? "block" : "none" }}
                >
                  <ul>
                    <li>
                      <Link to="/contact-us"> Contact Us </Link>
                    </li>
                    <li>
                      <Link to="/collaboration"> Collaborate </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                onClick={() => {
                  setopengethelp(!opengethelp);
                  setOpenProjectMenu(false);
                  setopenVolunteer(false);
                  setopenContactMenu(false);
                }}
              >
                <a href="#">
                  Get Help <i className="fa fa-chevron-down" />
                </a>
                <div
                  className="dropdown-mobile"
                  style={{ display: opengethelp ? "block" : "none" }}
                >
                  <ul>
                    <li>
                      <Link to="/add-volunteer-request">
                        Request for Volunteer
                      </Link>
                    </li>
                    <li>
                      <Link to="/add-scribe-request"> Request for Scribe</Link>
                    </li>
                    <li>
                      <Link to="/add-wish-request"> Make a Wish</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                onClick={() => {
                  setopenVolunteer(!openVolunteer);
                  setOpenProjectMenu(false);
                  setopengethelp(false);
                  setopenContactMenu(false);
                }}
              >
                <a href="#">
                  Volunteer <i className="fa fa-chevron-down" />{" "}
                </a>
                <div
                  className="dropdown-mobile"
                  style={{ display: openVolunteer ? "block" : "none" }}
                >
                  <ul>
                    <li>
                      <Link to="/scribe-requests"> Help Scribe Now </Link>
                    </li>
                    <li>
                      <Link to="/volunteer-requests"> Help Now </Link>
                    </li>
                    <li>
                      <Link to="/wishes">Fullfil A Wish</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/awards-and-media">Awards / Media </Link>
              </li>
              <li>
                <Link to="/gallery">Gallery </Link>
              </li>
            </ul>
            <div className="mobile-foo">
              {" "}
              <div className="login">
                <div className="text-center">
                  {!isAuthenticated ? (
                    <>
                      <Link to="/login">Login </Link>
                    </>
                  ) : (
                    <>
                      <a className="btn btn-sign-m" onClick={() => logout()}>
                        Logout
                      </a>
                    </>
                  )}
                </div>
              </div>
              <div className="mobile-soci">
                {" "}
                <ul class="social-box">
                  <li>
                    <a
                      href="https://www.facebook.com/SaveTheQuest/"
                      target="_blank"
                    >
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/savethequest" target="_blank">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/savethequest/"
                      target="_blank"
                    >
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
