import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ScribeRequestCard from "./common/ScribeRequestCard";
import Spinner from "./layout/Spinner";

function ScribeRequestsComponent({
  scriberequests,
  loading,
  activeUser,
  hide,
  showAll,
}) {
  return (
    <div>
      <section className="scribe">
        <div
          className="pattern-image"
          style={{ background: 'url("assets/images/bg-pattern-1.png")' }}
        />
        <div className="auto-container">
          <div className="sec-title centered mb-5 mt-3">
            <h2>
              Scribe Requests{" "}
              {!hide && (
                <span>
                  <Link to="/scribe-requests"> View All </Link>
                </span>
              )}
            </h2>
            <div className="text">We believe what we achieve</div>
          </div>
          <div className="scribe-request">
            <div className="auto-container">
              <div className="row">
                {!loading ? (
                  scriberequests &&
                  scriberequests.map((item) => {
                    console.log(
                      moment().diff(item.date_of_exam, "days"),
                      showAll
                    );
                    if (
                      moment().diff(item.date_of_exam, "hours") &&
                      moment().diff(item.date_of_exam, "hours") < 0
                    ) {
                      return (
                        <ScribeRequestCard
                          heading={`${item.course} ${item.subject} (${item.medium_of_exam} Medium)`}
                          location={`${item.exam_center} ${item.pincode}`}
                          month={item.time}
                          date={
                            item.date_of_exam &&
                            moment(item.date_of_exam).format("DD-MM-YYYY")
                          }
                          id={item.id}
                          activeUser={activeUser}
                          status={item.status}
                        />
                      );
                    }
                  })
                ) : (
                  <>
                    <Spinner />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ScribeRequestsComponent;
