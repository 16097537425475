import React from 'react'

function AwardsCard({image}) {
    return (
        <div className="col-md-4">
        <div className="post-media-title">
          <h2>Awards</h2>
        </div>
        <div className="post-3">
          <img src={image} />
        </div>
      </div>
    )
}

export default AwardsCard
