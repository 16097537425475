import {
  GET_SCRIBEREQUESTS,
  ADD_SCRIBEREQUEST,
  EDIT_SCRIBEREQUEST,
  GET_SCRIBEREQUEST,
  RESET_SCRIBEREQUEST,
  SCRIBEREQUESTS_ERROR,
} from "../types/scriberequest_type";

const initialState = {
  scriberequests: null,
  scriberequest: null,
  total_scriberequests: 0,
  loading: true,
  error: {},
  scriberequest_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SCRIBEREQUESTS:
      return {
        ...state,
        scriberequests: payload,
        loading: false,
        error: {},
      };

    case RESET_SCRIBEREQUEST:
      return {
        ...state,
        scriberequest: null,
        loading: true,
        error: {},
      };

    case ADD_SCRIBEREQUEST:
      return {
        ...state,
        scriberequest_message: payload,
        loading: false,
        error: {},
      };
    case GET_SCRIBEREQUEST:
      return {
        ...state,
        scriberequest: payload,
        loading: false,
        error: {},
      };
    case EDIT_SCRIBEREQUEST:
      return {
        ...state,
        scriberequest_message: payload,
        loading: false,
        error: {},
      };

    case SCRIBEREQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
