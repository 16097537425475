import React from "react";
import { Link } from "react-router-dom";
import VolunteerRequestCard from "./common/VolunteerRequestCard";
import Spinner from "./layout/Spinner";
function VolunteerRequestComponent({ volunteer_requests, loading, hide }) {
  return (
    <div>
      <section className="voluntter">
        <div
          className="pattern-image"
          style={{ background: 'url("assets/images/bg-pattern-1.png")' }}
        />
        <div className="auto-container">
          <div className="sec-title centered mt-3 mb-5">
            <h2>
              volunteer Request{" "}
              {!hide && (
                <span>
                  <Link to="/volunteer-requests"> View All </Link>
                </span>
              )}
            </h2>
            <div className="text">We believe what we achieve</div>
          </div>
        </div>
        <div className="voluntter-request">
          <div className="auto-container">
            <div className="row">
              {!loading ? (
                volunteer_requests &&
                volunteer_requests.map((item) => {
                  return (
                    <VolunteerRequestCard
                      heading={item.volunteer_required_for}
                      location={item.location_of_volunteering}
                      month={item.duration}
                      date={item.date}
                      status={item.status}
                      id={item.id}
                    />
                  );
                })
              ) : (
                <>
                  <Spinner />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VolunteerRequestComponent;
