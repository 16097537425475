import React from 'react'

function PageBanner({title}) {
    return (
        <div>
           <section className="page-banner">
  <div className="image-layer">
    <div className="auto-container">
      <div className="page-content">
        <h1>{title}</h1>
        <ul className="bread-crumb clearfix">
          <li className="active-page">
            <a href="#">Home</a>
          </li>
          <li><a href="#">{title}</a></li>
        </ul>
      </div>
    </div>
  </div>     
</section>

        </div>
    )
}

export default PageBanner
