import React from 'react'
import { Link } from 'react-router-dom'

function VolunteerCard({ volunteer }) {
  return (
    <div className="col-md-3 col-6 tra">
      <Link to={`/volunteers/${volunteer.id}`}>
        <div className="volunteer-image">
          <img
            src={
              volunteer.profile_photo
                ? volunteer.profile_photo
                : '/images/default.png'
            }
          />
        </div>
        <div className="volunteer-content">
          <div className="designation">Volunteer</div>
          <h4>
            {volunteer.first_name} {volunteer.last_name}
          </h4>
        </div>
      </Link>
    </div>
  )
}

export default VolunteerCard
