import React from 'react'

function TestimonialCard({image,paragraph,name}) {
    return (
        <div>
            <div className="voluntter-request">
    <div className="auto-container">
      <div className="testimonial-content">
        <img src={image} />
        <p>{paragraph}</p>
        <h2>{name}</h2>
      </div>
    </div>
  </div>
        </div>
    )
}

export default TestimonialCard
