import React from 'react'

function Bar({image,heading,paragraph,goalamount,raiseamount}) {
    return (
        <div>
             <div className="bar">
                <div className="bar-inner count-bar counted" data-percent="80%" style={{width: `${parseInt(raiseamount/goalamount*100)}%`}}>
                  <div className="count-text">{parseInt(raiseamount/goalamount*100)}%</div>
                </div>
              </div>
        </div>
    )
}

export default Bar
