import React from 'react'

function MediaCoverageCard({image,heading,content}) {
    return (
      
             <div className="col-md-4">
            <div className="post-media-title">
              <h2>Media Coverage</h2>
            </div>
            <div className="post-2">
              <img src={image} />
            </div>
            <div className="text-content">
              <h2>{heading}</h2>
              <p>{content}</p>
            </div>
          </div>
       
    )
}

export default MediaCoverageCard
