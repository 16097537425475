import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const gototop = () => {
    console.log("clicked");
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <footer className="footer">
        <div
          className="pattern-image"
          style={{ background: 'url("assets/images/bg-pattern-1.png")' }}
        />
        <div className="auto-container">
          <div className="row">
            <div className="col-md-4">
              <div className="bottom-section">
                <h2>About us</h2>
                <div className="bor" />
                {/* <div class="footer-logo">
<a href="#"><img src="assets/images/logo.png"></a>
                          </div> */}
                <div className="bottom-text">
                  <p>
                    <strong className="red-co">SAVE THE QUEST (STQ)</strong> is
                    a charitable trust registered under the Indian Trust Act of
                    1882, Section 60 (Registration no. 822) based in Delhi. The
                    society we live in is plagued by prejudices and clichéd
                    notions.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="logo ">
                      <a href="#">
                        <img src="/assets/images/logo.png" />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-6">
                    <div className="social-box-1 mt-4   ">
                      <li>
                        <a
                          href="https://www.facebook.com/SaveTheQuest/"
                          target="_blank"
                        >
                          <span className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/savethequest"
                          target="_blank"
                        >
                          <span className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/savethequest/"
                          target="_blank"
                        >
                          <span className="fa fa-instagram" />
                        </a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <div className="bottom-section">
                    <h2>Info Links</h2>
                  </div>
                  <div className="bor" />
                  <div className="widget-content">
                    <ul className="list">
                      <li>
                        <Link to="/about-us"> About Us</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>

                      <li>
                        <Link to="/awards-and-media">
                          Awards &amp; Media Coverage
                        </Link>
                      </li>
                      <li>
                        <Link to="/collaborate">Collaborate</Link>
                      </li>
                      <li>
                        <Link to="/campaigns">Campaigns</Link>
                      </li>
                      <li>
                        <Link to="/causes">Cure All</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div className="bottom-section">
                    <h2>Quick Links</h2>
                  </div>
                  <div className="bor" />
                  <div className="widget-content">
                    <ul className="list">
                      <li>
                        <Link to="/scribe-requests"> Help Scribe Now</Link>
                      </li>
                      <li>
                        <Link to="/add-wish-request">Make A Wish</Link>
                      </li>
                      <li>
                        <Link to="/wishes"> Fullfil A wish</Link>
                      </li>
                      <li>
                        <Link to="/volunteer-requests">Volunteer Requests</Link>
                      </li>

                      <li>
                        <Link to="/donate-now">Donation</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bottom-section">
                <h2>About us</h2>
                <div className="bor" />
                <div className="widget-content">
                  <ul className="footer-address-list ftr-details">
                    <li>
                      <span>
                        <i className="fa fa-map-marker" />
                      </span>{" "}
                      <p>
                        Address:{" "}
                        <span>
                          <a href="#">
                            40 2nd floor community centre, Greater Kailash 1 Rd,
                            near Kailash Colony Metro Station, Zamrudpur, New
                            Delhi, Delhi 110014
                          </a>
                        </span>
                      </p>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-phone" />
                      </span>{" "}
                      <p>
                        Phone:{" "}
                        <span>
                          <a href="#">+91-8585907442</a>
                        </span>
                      </p>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-map-marker" />
                      </span>{" "}
                      <p>
                        Email:{" "}
                        <span>
                          <a href="#"> info@savethequest.org</a>
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <hr />
            <div className="bottom-footer">
              <div className>
                <p>Copyrights © 2020 Save The Quest All Rights Reserved.</p>
              </div>
              <div className>
                <div className="bottom-footer-privacy">
                  <ul>
                    <li>
                      <Link to="/terms">Terms and Conditions</Link>
                    </li>
                    <li>
                      <a href="#">|</a>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy Policy</Link>
                    </li>
                    <li>
                      <a href="#">|</a>
                    </li>
                    <li>
                      <Link to="/cancellation">
                        {" "}
                        Cancellation &amp; Refund Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <button id="myBtn" onClick={() => gototop()} title="Go to top">
        <i className="fa fa-arrow-up"></i>
      </button>
      <div className="whatsapp-footer">
        <div className="whatsapp-fix">
          <a
            href="https://wa.me/918178456484?text=How May I Help You ?"
            target="_blank"
          >
            {" "}
            <img src="/images/whatsapp.png" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
