import {
  GET_PAGES,
  ADD_PAGE,
  EDIT_PAGE,
  GET_PAGE,
  RESET_PAGE,
  PAGES_ERROR,
} from "../types/page_type";

const initialState = {
  pages: null,
  page: null,
  total_pages: 0,
  loading: true,
  error: {},
  page_message: null,
};

export default function page_reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PAGES:
      return {
        ...state,
        pages: payload,
        loading: false,
        error: {},
      };

    case RESET_PAGE:
      return {
        ...state,
        page: null,
        loading: true,
        error: {},
      };

    case ADD_PAGE:
      return {
        ...state,
        page_message: payload,
        loading: false,
        error: {},
      };
    case GET_PAGE:
      return {
        ...state,
        page: payload,
        loading: false,
        error: {},
      };
    case EDIT_PAGE:
      return {
        ...state,
        page_message: payload,
        loading: false,
        error: {},
      };

    case PAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
