import React from 'react'

function FunfactComponent() {
    return (
        <div>
            <section className="fun-facts">
  <div className="auto-container">
    <div className="row">
      <div className="col-md-3">
        <div className="content text-center">
          <div className="counter">
            <p className="text-1">25000+</p>
          </div>
          <div className="counter-title">
            <p> Exam Scribes</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="content text-center">
          <div className="counter">
            <p className="text-2">2500+</p>
          </div>
          <div className="counter-title">
            <p> Volunteers</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="content text-center">
          <div className="counter">
            <p className="text-3">30000+</p>
          </div>
          <div className="counter-title">
            <p> People helped</p>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="text-center">
          <div className="counter">
            <p className="text-4">250+</p>
          </div>
          <div className="counter-title">
            <p>Fulfilled Wishes</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        </div>
    )
}

export default FunfactComponent
