import React from "react";
import BlogCard from "./common/BlogCard";
import Spinner from "./layout/Spinner";
import moment from "moment";
import { Link } from "react-router-dom";
function BlogsectionComponent({ blogs, loading, hide }) {
  return (
    <div>
      <section className="blog">
        <div className="auto-container">
          <div className="sec-title centered mb-5">
            <h2>
              Recent Blogs{" "}
              {!hide && (
                <span>
                  <Link to="/blogs"> View All </Link>
                </span>
              )}
            </h2>
          </div>
        </div>
        <div className="auto-container">
          {!loading ? (
            <div className="row">
              {blogs &&
                blogs.map((blog) => {
                  return (
                    <BlogCard
                      image={blog.featured_image}
                      date={moment(blog.pulished_date).format("DD MMM")}
                      month={moment(blog.pulished_date).format("YYYY")}
                      heading={blog.title}
                      paragraph={blog.content}
                      link={blog.slug}
                    />
                  );
                })}
            </div>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default BlogsectionComponent;
