import React from "react";
import { Link } from "react-router-dom";

function FeatureSection() {
  return (
    <div>
      <div className="fluid-section-one">
        <div className="outer clearfix">
          <div className="fluid-column">
            <div className="inner">
              <div className="icon-box">
                <img src="assets/images/donate.png" />
              </div>
              <div className="content-heart">
                <h3>Help Us by Donate</h3>
                <h2>Request For Scribe</h2>
              </div>
            </div>
            <Link to="/add-scribe-request" className="overlay-link" />
          </div>
          <div className="fluid-column">
            <div className="inner">
              <div className="icon-box">
                <img src="assets/images/collect-fund.png" />
              </div>
              <div className="content-heart">
                <h3>Help Us by Donate</h3>
                <h2>Become A Scribe</h2>
              </div>
            </div>
            <Link to="/signup" className="overlay-link" />
          </div>
          <div className="fluid-column">
            <div className="inner">
              <div className="icon-box">
                <img src="assets/images/christmas-star.png" />
              </div>
              <div className="content-heart">
                <h3>Help Us by Donate</h3>
                <h2>Make A Wish</h2>
              </div>
            </div>
            <Link to="/add-wish-request" className="overlay-link" />
          </div>
          <div className="fluid-column">
            <div className="inner">
              <div className="icon-box">
                <img src="assets/images/love.png" />
              </div>
              <div className="content-heart">
                <h3>Help Us by Donate</h3>
                <h2>Join Volunteer Bank</h2>
              </div>
            </div>
            <Link to="/signup" className="overlay-link" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
